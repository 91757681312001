.btn {
    @media (max-width: 991px) {
        font-size: $font-size-14;
    }
}

.btn_blue {
    background: $blue !important;
    color: $white !important;
    padding: 8px 45px;
    box-shadow: 2px 2px 10px $gray;
    font-weight: 500;
    &:focus {
        box-shadow: 2px 2px 10px $gray;
    }
}

.btn_gray {
    background: $lightblue;
    color: $blue_blue-60;
    padding: 6px 15px;
    font-weight: 500;
    &:focus {
        box-shadow: none;
    }
}

.btn_gradient {
    background: rgb(73, 103, 177);
    background: linear-gradient(0deg, rgba(73, 103, 177, 1) 47%, rgba(87, 115, 183, 1) 50%);
    color: $white !important;
    padding: 6px 35px;
    text-transform: uppercase;
    box-shadow: 3px 3px 15px $blue_blue-60;
    font-weight: 500;
    position: relative;
    i {
        color: $white;
    }
    &:focus {
        box-shadow: 3px 3px 15px $blue_blue-60;
    }
}

/* New Theme regarding Btn*/
.btn_primary_theme{
  background-color: $primary_color;
  color: #ffffff;
  border-color:  $primary_color;
  padding: 6px 35px;
  font-weight: 500;
    position: relative;
    i {
        color: $white;
    }
}
.a.btn_primary_theme:hover {
  color: $primary_color;
  background-color: #ffffff;
}
.btn_primary_theme:hover {
  color: $primary_color;
  background-color: #ffffff;
  border-color: $primary_color;
}