
.custom_container{
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 16px;
}
.wrapper_container {
    margin-top: 150px;
    position: relative;
    display: block;
}
.text_light_blue{
    color: $primary_color;
}
.text_blue{
    color: $blue_blue-50;
}
.text_dark_blue{
    color: $blue;
}
.text_green{
  color: $secondary_color;
}
.hr_line{
    margin: 40px 0px;
    border-bottom: 1px solid $blue_blue-80;
}
.mt-280{
    margin-top: 280px;
     @media (max-width:767px){
         margin-top: 180px;
    }
}

.cursor_pointer{ cursor: pointer;}



/*get_started_box*/

.get_started_box{
    width: 496px;
    background: #d7d8da;
    box-shadow: 10px 8px 25px $gray_light;
    margin: 0 auto;
    margin-top:5rem;
    padding: 100px;
    border-radius: 8px;
    text-align: center;
    h2{
        color: $primary_color;
        span{
            color: $primary_color;
        }
    }
    @media (max-width:767px){
        width: 80%;
        padding: 40px;
        margin-top:200px;
    }
}
/*get_started_box*/

.api_dashboard_box{
    width: 285px;
    // height: 135px;
    background: $lightblue;
    background: #fff;
    box-shadow: 10px 8px 25px $gray_light;
    margin: 5px;
    margin:25px 20px 0px;
    padding: 16px 20px;
    border-radius: 8px;
    text-align: center;
    @media (max-width:767px){
        width: 80%;
        padding: 10px;
        margin-top:20px;
    }
}

.dashboard_box{
    width: 300px;
    background: $lightblue;
    background: #fff;
    box-shadow: 10px 8px 25px $gray_light;
    margin: 0 auto;
    margin: 0 20px;
    margin-top:25px;
    padding: 20px;
    border-radius: 8px;
    @media (max-width:767px){
        width: 80%;
        padding: 40px;
        margin-top:5px;
    }
    span{
        color: #7794af;
        font-weight: 400;

    }
    h3{
        margin-top: 8px;
    }
}

.send_otp_box{
    width: 600px;
    margin: 0 auto;
    @media (max-width:991px){
        width: auto;
    }
}


.main_wrapper{
    padding-right: 20px;
    // float:right;
    @media (max-width:991px){
        width: auto;
        float:none;
        padding:20px;
        padding-top:70px;
    }
}

.action-btn{
    background: white;
    border: 1px solid $primary_color;
    color: $primary_color;
    i{
        color: $primary_color;
    }
    &:hover{
    background: $primary_color;;
    border: 1px solid $primary_color;
    color: #fff;
    i{
        color: #fff;
    }
    }
}



/*sidemodal-caa*/

.sidemodal{
    height: 100%;
    width: 500px;
    position: fixed;
    box-shadow: 1px 2px 10px #ddd;
    z-index: 1000;
    top: 0px;
    right: 0px;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    .sidemodal_header{
        padding: 0px 12px;
        height: 40px;
        display: flex;
        align-items: center;
        background: #e0e5ed;
        font-size: 16px;
        a{
            color:#3565b9;
            font-weight: 600;
            text-decoration: none;
        }
        .sidemodal_close_arrow{
            position: absolute;
            right: 12px;
            cursor: pointer;
            font-size: 16px;
        }
    }

    .sidemodal_inner{
        height: calc(100vh - 120px);
        padding-top: 20px;
        overflow-y: auto;
        .sidemodal_tab{
            margin-left: 12px;
            display: inline-block;
            width: 155px;
            .side_nav{
                width: 100%;
                margin-left: 0px;

            }
        }
    }

    .sidemodal_footer{
        height: 80px;
        border-top: 1px solid #e0e5ed;
        padding: 20px 12px;
        width: 100%;
        text-align: right;
    }
}
/*sidemodal-caa-END*/



/*filter_wrapper-css*/
.filter_wrapper{
    padding: 20px 12px;
    position: relative;
    border-radius: 5px;
    background: #fff;
    position: relative;
    border:1px solid #d0dbed;

    input{
        font-size: 14px;
    }
    select{
        height: 42px;
        margin-bottom: 15px;
        position: relative;
        border: 1px solid #d0dbed;
        font-size: 14px;
        &:focus{
            box-shadow: 0 0 9px 0.25rem rgba(13, 110, 253, 0.16%);
        }
    }
}

.filter_tab{
    border: 0px !important;
    .nav-item{
        padding: 6px 12px;
    }
    a{
        font-size: 14px;
        color: #808ca2;
        text-decoration: none;
    }
    .active{
        color: #3565b9;
        border-bottom: 2px solid #3565b9;
    }
}
.filter_link{
    color: #6db0ee;
        font-size:14px;
}

/*filter_wrapper-css*/

/*NEW THEME */
.text_black{
  color:  $primary_color;
}

.black-text {
  color: black !important;
}

.advisory-close {
  position: absolute;
  height: 26px;
  width: 26px;
  left: auto;
  right: -13px;
  bottom: auto;
  top: -13px;
  padding: 0px;
  color: #ffffff;
  font-family: Arial;
  font-weight: 100;
  font-size: 24px;
  line-height: 24px;
  border: 2px solid #ffffff;
  border-radius: 26px;
  box-shadow: 0px 0px 15px 1px rgba(2, 2, 2, 0.75);
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.23);
  background-color: rgba(0, 0, 0, 1.00);
  opacity: 1;
}

@media (min-width: 1200px) {
  .modal-adv {
      max-width: 950px;
  }
}

#advisoryModelOpen {
  color: #D80101 !important;
  strong {
    color: #D80101 !important;

  }
}
