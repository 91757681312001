
footer{
    //  position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    right: 0px;

    bottom: 0px;
    text-align: center;
     @media (max-width:1599px){
         padding-top: 100px;
        //  position:relative;
     }
}