



/*-------------------custom_radio-------------*/
.custom_radio{
    position: relative;
    color: $black;
    padding-left: 30px;
    margin-right:30px;
    font-weight: 500;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        border:2px solid $gray_light;
        border-radius: 50%;
        &:after{
            content: "";
            position: absolute;
            display: none;
        }
    }
    @media (max-width:767px){
        margin-bottom: 20px !important;
        font-size:$font-size-12;
    }
}
.custom_radio input:checked ~ .checkmark:after {
    display: block;
}
.custom_radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $blue;
}

.custom_radio input:checked ~ .checkmark {
    border:2px solid $blue;;
}
/*------------------custom_radio-END----------------*/


/*-------------------custom_checkbox-------------*/
.custom_checkbox {
    position: relative;
    color:$black;
    padding-left:24px;
    font-weight: 500;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

    }
    .checkmark {
        position: absolute;
        top:2px;
        left: 0;
        height: 15px;
        width: 15px;
        border-radius: 4px;
        border:1px solid $gray_light;
    }
}

.custom_checkbox input:checked ~ .checkmark {
    background-color: $blue;
    border-color: $blue;
}


.custom_checkbox input:checked ~ .checkmark {
    &:after{
        content: "";
        position: absolute;
        display: none;  
    }
}
.custom_checkbox input:checked ~ .checkmark:after {
    display: block;
}
.custom_checkbox .checkmark:after {
    left: 4px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid $white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*----------------------------------custom_checkbox--------------------------*/


.custom_input{
    input
    
    {
        // height: 42px;
        // margin-bottom: 15px;
        position: relative;
        // border:1px solid $primary_color;
        &:focus{
            box-shadow: 0 0 9px 0.25rem rgba(13, 110, 253, 0.16%);
        }

    }
    ::placeholder {
        color:$gray;
        font-style: italic;
        opacity: 1; 
    }

    :-ms-input-placeholder { 
        color:$gray;
        font-style: italic;
    }

    ::-ms-input-placeholder { 
        color:$gray;
        font-style: italic;
    }
    .drop_arrow{
        position: absolute;
        right: 10px;
        top: 10px;
        color:$gray;
    }
}

label{
    color: $primary_color;
    // font-weight: 500;
    margin-bottom: 0px !important;
}

.custom_textarea{
    height: 42px;
    resize: none;
    position: relative;
    border:1px solid $blue_blue-80;
    &:focus{
        box-shadow: 0 0 9px 0.25rem rgba(13, 110, 253, 0.16%);
    }
}


.custom_select{
    width: 100%;
    border:1px solid $blue_blue-80;
    border-radius: 0px 0px 4px 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    background: $white;
    left: 0px;
    top: 40px;
    text-align: left;
    input{
        height:  auto;
    }
    label{
        border-bottom: 1px solid $blue_blue-80;
        width: 100%;
        padding-bottom: 12px;
    }
}
.registration_form_wrapper{
    width: 900px;
    margin: 0 auto;
    .custom_input{
        input{
            // margin-bottom: 0px;
        }
    }
    @media (max-width:991px){
        width: 90%;
    }
}

.registration_form_wrapper_pos{
    position: relative;
    left: -60px;
     @media (max-width:767px){
        left:0px;
    }
}


/*//////////////////////Multiform////////////////////////////////////////////*/