

.side_nav{
    width: 230px;
    margin-left: 20px;
    // float: left;
    display: inline-flex;
    ul{
        margin: 0px;
        padding: 0px;
        li{
            list-style-type: none;
            a{
                display: block;
                padding: 10px 15px;
                text-decoration: none;
                border-bottom: 1px  dashed;
                border-color: $blue_blue-80 ;
                color:$primary_color;

            }
            .filter-active {
                background: $primary_color;
                color: $white;
                border-radius: 5px;

                border-bottom: 0px;
            }
        }
    }
    @media screen and (max-width: 991px){
        background: $white;
        width: 190px;
         float: right;
    }
}