.iris-custom_tab {
  .nav-tabs {
    border-bottom: none;
  }

  .nav-link {
    padding: 0;
    border: none;
  }

  .custom_radio {
    margin-right: 20px;
  }
}

.width-200 {
  width: 200px;
}

.error {
  input {
    border: 1px solid red;
  }

  .error-msg {
    color: red;
  }
}

.chart-card {
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 0px 12px #d5d8e1;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }
}

.iris-dashboard-cards {
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 0px 12px #d5d8e1;
  margin-right: 16px;

  &.card-blue {
    border-top: 10px solid #64c8f0;
  }

  &.card-green {
    border-top: 10px solid $secondary_color;
  }

  &.card-red {
    border-top: 10px solid #c76262;
  }

  &.card-yellow {
    border-top: 10px solid #f7b92f;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  .card-label {
    color: $primary_color;
  }

  .card-value {
    // font-weight: bold;
    font-size: 16px;
    color: #000;
  }

  &.last-card {
    margin-right: 0px;
  }
}

.contextual-button {
  padding: 6px 8px;
  background: $primary_color;
  border-color: $primary_color;
  border: 2px solid $primary_color;

  i {
    font-weight: bold;
  }

  &:hover {
    border: 2px solid $primary_color;

    i {
      color: $primary_color !important;
    }
  }
}

.opt-for-storage-alert {
  display: flex;
  align-items: center;

  // box-shadow: 0px 0px 22px #ffd3d7;
  @media (max-width: 767px) {
    flex-flow: column;
  }

  span {
    color: #842029;
  }

  .opt-for-storage-alert-view-btn {
    background: #fff;
    border: 2px solid $primary_color;
    font-size: 14px;
    color: $primary_color;
    // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
    padding: 6px 20px;

    &:hover {
      background: $primary_color;
      color: #fff;
    }
  }

  .opt-for-storage-alert-btn {
    // background: rgb(114, 171, 128);
    // background: -moz-linear-gradient(
    //   0deg,
    //   rgba(114, 171, 128, 1) 41%,
    //   rgba(138, 185, 148, 1) 100%
    // );
    // background: -webkit-linear-gradient(
    //   0deg,
    //   rgba(114, 171, 128, 1) 41%,
    //   rgba(138, 185, 148, 1) 100%
    // );
    // background: linear-gradient(
    //   0deg,
    //   rgba(114, 171, 128, 1) 41%,
    //   rgba(138, 185, 148, 1) 100%
    // );

    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#72ab80",endColorstr="#8ab994",GradientType=1);

    background-color: $primary_color;

    font-size: 12px;
    color: #fff;
    // border-color: rgb(114, 171, 128);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
    padding: 8px 24px;
    font-size: 16px;

    i {
      color: #fff;
    }
  }
}

.iris-dashboard-cards {
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 0px 12px #d5d8e1;
  margin: 0px;
  height: 100%;
}

.iris-quick-generate-btn {
  color: #fff;

  &.blue {
    background: #4967b1;
    border-color: #4967b1;
  }

  &.green {
    background: $secondary_color;
    border-color: $secondary_color;
  }

  &.red {
    background: #bf404c;
    border-color: #bf404c;
  }

  &.yellow {
    background: #f7b92f;
    border-color: #f7b92f;
  }
}

.iris-video-container {
  border: 1px solid #e8e8e9;
  padding: 20px;
  margin-bottom: 36px;

  .carousel-indicators {
    margin-bottom: 0;
    bottom: -66px;

    .active {
      background-color: $primary_color !important;
    }
  }

  .carousel-indicators [data-bs-target] {
    background-color: #bcc7da;
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: auto;
    bottom: -82px;
    width: auto;
    opacity: 0.2;
  }

  .carousel-control-next {
    right: -22px;

    &:hover {
      opacity: 0.5;
    }
  }

  .carousel-control-prev {
    right: 32px;
    left: auto;

    &:hover {
      opacity: 0.5;
    }
  }
}

.iris-news-updates {
  h6 {
    margin: 0;
    font-size: 14px;
    margin-bottom: 4px;
    // color: #3565b9;
    font-weight: 700;
  }
}

.top-space-55 {
  margin-top: 55px;
}

body .hidePaginator .ui-table .ui-paginator .ui-paginator-current {
  visibility: hidden !important;
}

/* Common Header scss for Taxpayer and API Integrator Dashboard*/
.logo {
  width: 100px;
  //   margin-top: -12px;
  margin-right: 10px;
}

nav {
  background: $primary_color;
  // border-bottom: 1px solid #e5e5e5;
  top: 0px;
  height: 60px;
  // box-shadow: 0px 0px 22px #eeeeef
}

nav ul {
  margin-top: 0px;
}

nav ul li a {
  color: #fff;
  border: 0px;
  padding: 14px 18px;
  font-weight: 600;
}

nav ul li a:hover {
  color: #fff;
}

nav ul li i {
  color: #fff;
}

nav ul li .dropdown-menu li a {
  color: #000;
  display: block;
  padding: 10px 18px;

  &:hover {
    background: $primary_color;
    color: #fff;
  }
}

.dropdown:hover .dropdown-menu {
  // display: block;
  // position: absolute;
  // margin: 0px;
  // min-width: 25%!important;
  // margin: 0 auto;
  // overflow: auto;
}

nav ul li .dropdown-menu {
  background: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.36);
  margin-top: 0px !important;
  border-radius: 5px;
  // width:600px !important;
}

nav ul li {
  color: $primary_color;
}

nav ul li a:after {
  display: none;
}

.breadcrumb {
  border-bottom: 0px;
}

.user_pic {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: 500;
  height: 40px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background: #6db0ee;
  margin-right: 5px;
  overflow: hidden;
}

.user_pic img {
  width: 100%;
}

.user_admin {
  //   padding-left: 10px;
  display: inline-block;
}

.user_admin .dropdown-menu {
  padding: 0px;
  min-width: 110px;
  margin-top: 6px !important;
  border-radius: 5px;
}

.top-space-55 {
  margin-top: 15px;
}

footer {
  padding-top: 0;
  display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    right: 0px;
    bottom: 0px;
    background: #fff;
    text-align: center;
    z-index: 999;
    border-top: 1px solid #ccc;
}

.footer-links {
  font-size: 14px;
  margin: 0px 10px;
  color: $secondary-color;
}

.footer-body {
  font-size: 16px;
  font-weight: 400;
  color: rgb(51, 66, 94);
  text-decoration: none;
}

.alert-err {
  background-color: #f3e7e7 !important;
}

.opt-for-storage-alert {
  position: fixed;
  left: 50%;
  bottom: 10px;
  width: 800px;
  transform: translateX(-50%);
  padding: 8px;
  z-index: 9;
  background-color: #fff !important;
  border-color: #fa645c !important;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.mouse-pointer {
  cursor: pointer;
}

.close-opt-icon {
  margin-right: 10px;
  cursor: pointer;
  color: #9d7175;

  @media (max-width: 767px) {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.opt-for-storage-alert .opt-for-storage-alert-btn {
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid $primary_color;

  &:hover {
    background: #fff;
    border: 2px solid $primary_color;
    color: $primary_color;

    i {
      color: $primary_color;
    }
  }
}

.alert-announcement {
  background-color: #d7d8da;
  padding-left: 60px;
}

.vertical-center {
  height: 50%;
  align-items: center;
}

.generate-btn {
  border-radius: 5px;
  // background: #4ea445;
  padding: 6px 12px;
  color: #fff !important;
  transition: all 0.25s;
  border: 0px;
  // margin-left: 60px;
  //   margin-top: -11px;
  background-color: $secondary_color;
  border-color: $secondary_color;
  font-weight: 500;
  border: 2px solid $secondary_color;
  font-size:13px;
}

.generate-btn:hover {
  background-color: #fff;
  border: 2px solid $primary_color;
  color: $primary_color !important;
  border-radius: 5px;
  border-color: #000000;
  font-weight: 500;
  font-size:13px;
}

.generate-btn-inner {
  background-color: #fff;
  transition: 0.5s;
  width: 260px !important;
  transition: all 0.25s;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.36) !important;
  border-radius: 5px;
  padding: 30px !important;
}

.generate-btn-inner .btn {
  white-space: nowrap;
  margin-bottom: 8px;
  display: block;
  width: 200px;
  height: 44px;
}

.generate-btn-inner {
  li:last-child {
    padding-right: auto;
    float: none;
  }
}

.generate-btn-inner .btn:focus {
  box-shadow: none;
}

.generate-btn-inner .blue:hover {
  background: #3a518d;
}

.generate-btn-inner .green:hover {
  background: #549b01;
}

.generate-btn-inner .red:hover {
  background: #973b44;
}

.generate-btn-inner .yellow:hover {
  background: #fbb00a;
}

.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.right-open {
  right: 0;
}

@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
    top: 40px;
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
    top: 40px;
  }
}

@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
    top: 40px;
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
    top: 40px;
  }
}

.announcement-icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-56%);
  width: 50px;
}

// .dropdown:hover>.dropdown-menu {
//   display: block;
//  }

.news-img {
  width: 100%;
  object-fit: cover;
}

.wrap {
  white-space: pre-line;
}

.test {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(151px, 59px);
}

.display {
  display: block !important;
}

.dropdown-menu2 {
  position: absolute;
  display: none;
  background: #fff;
  width: 50%;
}

.dropdown-menu-select {
  position: absolute;
  display: none;
  background: #fff;
}

.settings-mega-menu {
  width: 50%;
  right: 20px;
}

.purchase-mega-menu {
  width: 60%;
}

.notifications-mega-menu {
  width: 35%;
  right: 40px;
}

.custom-badge {
  font-weight: 400;
}

.horizontal-center {
  display: table;
  margin: 0 auto;
}

.helptextlabel {
  margin-left: 3rem;
  margin-right: 3rem;
}

.five-stepper {
  width: 184px !important;
}

.chart {
  position: relative;
  min-height: unset;
}

/* Common header for Taxpayer and API Integrator end */

.notification-body {
  border-bottom: 1px solid grey;
}

.notification-img {
  width: 50px !important;
}

.secondary-links {
  color: $secondary-color !important;
}

.vertical-center-badge {
  vertical-align: middle;
}

.bi-info-circle-fill {
  color: $secondary-color;
}

// Modal SCSS
.modal-title {
  color: $primary_color;
}

.icon-primary {
  color: $primary_color;
}


/*New Datatable CSS changes dated November 2022*/
/*New Datatable CSS changes dated November 2022*/
body {
  .ui-table {
    .ui-table-thead>tr>th {
      word-break: break-word;
      border: none !important;
      background-color: #252525 !important;
      color: #fff !important;
      padding: 0.571em 0.457em !important;

      span {
        color: #fff !important;
      }
    }

    .ui-table-tbody>tr>td {
      border: none !important;
      border-bottom: 1px solid #d9d9d9 !important;
      background-color: #ffffff !important;
      color: #707070 !important;
    }

    .ui-table-tbody>tr {
      background-color: #f8f8f7;
      color: #252525;
    }

    .ui-paginator {
      background-color: #fff !important;
      border: none !important;
      border-bottom: 1px solid #d9d9d9 !important;
      display: flex;
      align-items: center;

      .ui-paginator-current {
        line-height: 1 !important;
        // float: left !important;
        display: inline-block;
        width: 50%;
        text-align: right;
        margin-right: auto;
      }
    }
  }
}

/*Once Table is activated*/
body .ui-table .ui-table-tbody>tr:focus>td:first-child,
body .ui-table .ui-table-tbody>tr:focus>td {
  box-shadow: none !important;
  background-color: #f8f8f7 !important;
  color: #252525 !important;
}
body .ui-table .ui-table-tbody>tr>td.rm-pd {
  padding-left: 0.15em;
}

body .ui-paginator .ui-dropdown {
  border: 1px solid #707070 !important;
  height: auto !important;
  margin-left: 0 !important;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  border-radius: 50% !important;
  background-color: #252525 !important;
}

body .ui-paginator .ui-paginator-pages {
  height: auto !important;
}


.custom-link {
  color: $secondary-color;
}

#spinning-circle {
  animation-name: spinning-circle;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  width: 50px;
  height: 50px;
}

@keyframes spinning-circle {

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.border-success {
  border-color: $secondary-color !important;
}

.text-success {
  color: $secondary-color !important;
}

// Checkbox SCSS
.ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: $primary_color !important;
  background-color: $primary_color !important;
  color: $white;

}

.ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  color: $white !important;
}

.ui-table .ui-table-tbody>tr.ui-state-highlight a {
  color: $secondary-color !important;
}

.ui-table .ui-table-tbody a {
  color: $secondary-color !important;
}

.primary-label {
  color: $secondary-color;
}

.error-field {
  color: $amber !important;
  font-size: 18px;
  font-weight: 500;

}

.action-class {
  font-weight: 600;
  color: $primary_color;
}

.icon-helptext {
  margin-right: 5px;
}

.form-check-input:checked {
  background-color: $secondary_color !important;
  border-color: $secondary_color !important;
}

.general-info-alert {
  position: fixed;
  left: 50%;
  bottom: 10px;
  width: 800px;
  transform: translateX(-50%);
  padding: 8px;
  z-index: 9;
  background-color: #fff !important;
  border-color: #252525 !important;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.error-card {
  border-color: #ff0000b0;
}

.font-weight-bold {
  font-weight: 600;
}

.word-next-line {
  word-break: break-all;
}

.breadcrumbPT {
  padding-top: 0px;
}

.input-group-text {
  color: #252525;
  background-color: #f1f1f0;
}

@media (max-width: 991px) {
  .multi_form ul li:after {
    width: 88%;
    left: 40%;
    top: -38px;
  }

  .multi_form ul li:before {
    width: 25px;
    height: 25px;
    line-height: 24px;
  }

  .five-stepper {
    width: 130px !important;
  }

  .lblLiText {
    font-size: 12px;
  }

  .multi_form ul {
    margin-top: 10px;
  }

  .text-end {
    text-align: center !important;
  }

  .btn_primary_theme {
    padding: 6px 22px;
  }

  .font-size-14 {
    font-size: 13px;
  }

  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 568px !important;
  }

  // .ellipsis {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   width: 40%;
  // }

  .qr-head {
    display: block !important;
  }

  .offset-3 {
    margin-left: 16%;
  }

  .helptextlabel {
    margin-right: 0px;
  }

  .align-center {
    margin-left: 5%;
  }
}

.mt15 {
  margin-top: 15px;
}

.success-field {
  color: #5dab00;
}
input,
input::placeholder {
font-size:12px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{
  font-size: 12px!important;
  font-style: italic;
  color:rgb(119 132 158)!important;
}
@media (max-width: 1280px) {
  .purchase-mega-menu{
    width: 72%;
    right: 4%;
  }
  .tools-mega-menu{
    width: 72%;
    right: 4%;
  }
}
@media (max-width: 1024px) {
  .purchase-mega-menu{
    width: 72%;
    right: 4%;
  }
  .tools-mega-menu{
    width: 72%;
    right: 4%;
  }
  .gradient_wrapper {
    width: 102.8%;
  }
}

@media (max-width: 991px) {
  .iris-dashboard-cards{padding: 15px;}
  .iris-dashboard-cards2{padding: 15px;}
  .gradient_wrapper {
    width: auto;
  }

  .multi_form ul li:after {
    width: 88%;
    left: 40%;
    top: -38px;
  }

  .iris-video-container .carousel-control-prev {
    right: 15px;
    left: auto;
  }

  .font-size-40 {
    font-size: 28px;
  }

  .carousel-indicators [data-bs-target] {
    width: 16px;
  }

  .multi_form ul li:before {
    width: 25px;
    height: 25px;
    line-height: 24px;
  }

  .five-stepper {
    width: 147px !important;
  }

  .lblLiText {
    font-size: 12px;
  }

  .multi_form ul {
    margin-top: 10px;
  }

  .text-end {
    text-align: center !important;
  }

  .btn_primary_theme {
    padding: 6px 15px!important;
  }

  .font-size-14 {
    font-size: 13px;
  }

  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 568px !important;
  }

  .ellipsis {
    word-wrap: break-word;
    width: 200px;
  }

  .qr-head {
    display: block !important;
  }

  .offset-3 {
    margin-left: 16%;
  }

}
@media (max-width: 912px) {
  .five-stepper {
    width: 164px !important;
}
}
@media (max-width: 820px) {
  .five-stepper {
    width: 147px !important;
}
}
@media (max-width: 800px) {
  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 378px !important;
  }

}

@media (max-width: 768px) {
  .five-stepper {
    width: 138px !important;
  }
  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 345px !important;
  }

  body .ui-table .ui-paginator .ui-paginator-current {
    font-size: 12px;
    display: none;
  }

  body .ui-paginator .ui-paginator-pages .ui-paginator-page {
    height: 1.386em !important;
    min-width: 1.486em !important;
    line-height: 1.486em !important;
  }

  body .pi {
    font-size: 0.65em !important;
  }

  body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    font-size: 1.15em !important;
  }

}

@media (max-width: 680px) {
  .multi_form ul li:after {
    width: 87%;
    left: 40%;
    top: -38px;
  }

  .multi_form ul li:before {
    width: 25px;
    height: 25px;
    line-height: 24px;
  }

  .five-stepper {
    width: 122px !important;
  }

  .lblLiText {
    font-size: 12px;
  }

  .multi_form ul {
    margin-top: 10px;
  }

  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 255px !important;
  }

}

@media (max-width: 640px) {
  .multi_form ul li:after {
    width: 87%;
    left: 40%;
    top: -38px;
  }

  .multi_form ul li:before {
    width: 25px;
    height: 25px;
    line-height: 24px;
  }

  .five-stepper {
    width: 113px !important;
  }

  .lblLiText {
    font-size: 12px;
  }

  .multi_form ul {
    margin-top: 10px;
  }

  .custom_radio {
    padding-left: 25px;
    margin-right: 15px;
  }

  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 215px !important;
  }
}

@media (max-width: 600px) {
  .multi_form ul li:after {
    width: 87%;
    left: 40%;
    top: -38px;
  }

  .multi_form ul li:before {
    width: 25px;
    height: 25px;
    line-height: 24px;
  }

  .five-stepper {
    width: 102px !important;
  }

  .lblLiText {
    font-size: 12px;
  }

  .multi_form ul {
    margin-top: 10px;
  }

  .form-control {
    width: 95%;
  }

  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 175px !important;
  }
}

@media (max-width: 540px) {
  .five-stepper {
    width: 95px !important;
  }
}

@media (max-width: 480px) {
  .multi_form ul li:after {
    width: 75%;
    left: 40%;
    top: -38px;
  }

  .multi_form ul li:before {
    width: 25px;
    height: 25px;
    line-height: 24px;
  }

  .five-stepper {
    width: 85px !important;
  }

  .lblLiText {
    font-size: 12px;
  }

  .multi_form ul {
    margin-top: 10px;
  }

  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 55px !important;
  }

  .custom_radio {
    padding-left: 25px;
    margin-right: 4px;
  }

  #addgstinconst .col-sm-3 {
    width: 10%;
  }

  #addgstinconst .col-sm-9 {
    width: 90%;
  }
}

@media (max-width: 414px) {
  .five-stepper {
    width: 72px !important;
  }
}

@media (max-width: 400px) {
  .multi_form ul li:after {
    width: 75%;
    left: 40%;
    top: -38px;
  }

  .multi_form ul li:before {
    width: 25px;
    height: 25px;
    line-height: 24px;
  }

  .five-stepper {
    width: 72px !important;
  }

  .lblLiText {
    font-size: 10px;
  }

  .multi_form ul {
    margin-top: 10px;
  }

  #profileDropdown {
    right: 0 !important;
    top: 67px;
    left: 19px !important;
  }

  ul.profile-dropdown {
    width: 370px;
  }

  ul.profile-dropdown li:first-child {
    width: 348px;
  }

  .custom_radio {
    padding-left: 25px;
    margin-right: 4px;
  }
}

@media (max-width: 393px) {
  .five-stepper {
    width: 69px !important;
  }
}

@media (max-width: 390px) {
  .five-stepper {
    width: 69px !important;
  }
}

@media (max-width: 375px) {
  .five-stepper {
    width: 66px !important;
  }
}

@media (max-width: 360px) {
  .five-stepper {
    width: 63px !important;
  }
}

@media (max-width: 280px) {
  .five-stepper {
    width: 50px !important;
  }

  body .ui-paginator .ui-paginator-first {
    min-width: 2.086em !important;
  }

  body .ui-paginator .ui-dropdown {
    display: none;
    ;
  }

  .iris-video-container .carousel-control-prev {
    right: 6px;
    left: auto;
  }

  .font-size-40 {
    font-size: 22px;
  }

  .chart-card h4 {
    font-size: 12px;
  }
  .btn {
    font-size: 12px;
}
}
