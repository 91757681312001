
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import "variables";
@import "utilities";
@import "signup";
@import "form";
@import "header";
@import "button";
@import "multi_form";
@import "common";
@import "nav";
@import "footer";
@import "breadcrumb";
@import "side_nav";
@import "table";
@import "tab";
@import "new-style";


*,html,body {
    color: $default_color;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.custom-header_container{
      max-width: 1600px;
    margin: 0 auto;
}

body{
    background: none;
}


.gradient_wrapper{
    // background-image:url(../images/footer_logo.png);
    // , linear-gradient(158deg, rgba(255,255,255,1) 59%, rgba(224,232,244,1) 100%);
    min-height:calc(100vh - 142px);
    background-position: bottom right;
    background-size:200px;

    background-repeat: no-repeat;
    position: relative;
    padding-bottom: 140px;

    @media screen and (max-width: 1599px){
        height:auto;
    }

    @media screen and (max-width:1023px){
        background-size: 120px;
        //  background-image:url(../images/footer_logo.png);
        //  , linear-gradient(158deg, rgba(255,255,255,1) 98%, rgba(224,232,244,1) 100%);
    }
     @media screen and (min-width:1024px) and(max-width:1500px){
        background-size:200px;
        //  background-image:url(../images/footer_logo.png);
        //  , linear-gradient(158deg, rgba(255,255,255,1) 90%, rgba(224,232,244,1) 100%);
    }
}

.urdu {
  direction: rtl !important;
  text-align: right !important;
  font-family: 'Noto Nastaliq Urdu', 'Jameel Noori Nastaleeq', serif;
}


.gradient_height{
    height:calc(100vh - 280px);
    @media screen and (max-width: 1599px){
        height:auto;
    }
}
.toast-container{
    color:#fff;
}
.text-link{
    color: $primary_color
}
a:hover {
  color: $primary_color;
}
input[type="checkbox"]{
    cursor: pointer;
}
.ngx-pagination .current{
    padding: 0.1875rem 0.625rem;
    background: #3565b9 !important;
    color: #fefefe;
    cursor: default;
    border-radius: 4px;
    color: #fff !important;
    span{
        color: #fff !important;
    }
}

@import url(https://fonts.googleapis.com/css?family=Lato:700);
.subscription-box {
  position: relative;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  &:hover {
    transform: scale(1.02);
    transition: 0.3s;
  }
  .subscription-title {
    position: absolute;
    top: 20px;
    left: 20px;
    .current-plan {
      color: #3565B9;
    }
    .recommended-plan {
      color: green;
    }
  }
  .subscription-content {
    padding-top: 100px;
  }
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    span.current-plan {
      position: absolute;
      display: block;
      width: 225px;
      padding: 15px 0;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      color: #fff;
      background-color: #3565B9;
      font: 700 18px/1 "Lato", sans-serif;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;
    }
    span.recommended-plan {
      position: absolute;
      display: block;
      width: 225px;
      padding: 15px 0;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      color: #fff;
      background-color: green;
      font: 700 18px/1 "Lato", sans-serif;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;
    }
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #EEEEEE;
  }
  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
    span {
      left: -25px;
      top: 30px;
      transform: rotate(45deg);
    }
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  ul.details {
    max-height: 360px;
    overflow: auto;
  }
  li {
    word-break: break-word;
  }
}
.error{
  color: #ff0000b0;
  font-size: 14px;
  margin: 2px;
}
.selected-btn{
  background: #4a5eda;
  color: white;
}
.selected-btn:hover{
  color: white!important;
}
.btn-tabs{
  // margin-bottom: 0px!important;
  border-bottom: 1px solid grey;
    button{
      width: 100%;
    }
    div{
      padding: 0px!important;
    }

}
