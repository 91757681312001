/* You can add global styles to this file, and also import other style files */
@import "src/assets/scss/style.scss";

.toast-message a,
.toast-message label {
  color: #ffffff !important;
}

.toast-container .ngx-toastr {
  color: #ffffff !important;
  font-size: 12px !important;
}

/*for common tab*/
.custom_tab .nav-tabs li a,
.custom_tab .nav-tabs li button {
  display: block;
  color: $primary_color;
  text-align: center;
  padding: 10px 16px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #d0dbed;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

// .nav-link.active {
//   background-color: #aebacd;
// }
.custom_tab .nav-tabs .nav-item.show .nav-link,
.custom_tab .nav-tabs .nav-link.active {
  color: $button_hover_color;
  background: $primary_color;
  border-bottom: none;
}

.custom_tab .nav-tabs {
  // border-bottom: none;
}

/*for common tab*/

.toast-message {
  color: #ffffff !important;
}

.basic {
  color: rgb(38 103 174 / 80%) !important;
}

//New Navbar CSS
.custom_navbar {
  padding-top: 0;
  padding: 20px 0;
  font-family: Poppins;
  background: #faf9fa !important;
  height: 80px;

  .custom_navbar_nav {
    justify-content: end;
  }

  .dropdown-menu {
    width: max-content !important;
  }

  a.nav-link.active {
    color: #3c8f69 !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 60px;
    background: #fff !important;
    position: relative;
    border-bottom: 4px solid #3c8f69;

    // &::after {
    //   content: "";
    //   height: 4px;
    //   width: 100%;
    //   position: absolute;
    //   left: 0;
    //   bottom: -5px;
    //   z-index: 203;
    //   opacity: 1;
    //   background-color: #3c8f69;
    // }
  }

  a.nav-link {
    font-size: 14px;
    font-weight: 600;
    line-height: 60px;
    color: #252525 !important;
    padding: 0 20px;
    position: relative;

    span {
      font-weight: 600;
    }

    &:hover {
      color: #3c8f69;
      border-bottom: 4px solid #3c8f69;
      // &::after {
      //   content: "";
      //   height: 4px;
      //   width: 100%;
      //   position: absolute;
      //   left: 0;
      //   bottom: -5px;
      //   z-index: 2;
      //   opacity: 1;
      //   background-color: #3c8f69;
      // }
    }
  }

  .btn-dark {
    background-color: #252525;
    border-style: solid;
    padding: 12px 20px !important;
    line-height: 14px !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    border-width: 3px;
    border-radius: 8px;
    white-space: nowrap;

    &:hover {
      border: 3px solid #252525;
      background-color: #fff;
      color: #252525;
    }
  }

  .btn {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
  }

  a.dropdown-item {
    color: #5f5f5f;
    background-color: rgba(0, 0, 0, 0.06);
    padding: 10px 20px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
      color: #2e2e2e;
      background-color: #dde1e0;
    }
  }

  ul.dropdown-menu {
    padding: 0 !important;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    /* remove the gap so it doesn't close */
    overflow: inherit !important;
  }

  .dropdown:hover .submenu {
    display: none;
  }

  .dropdown:hover .submenu.show {
    display: block;
  }

  .submenu_span {
    float: right;
  }
}

.custom_container_fluid {
  z-index: 1000;
  position: relative;

  > .row {
    background-color: #fff;

    p {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
    }
  }
}

.language-switch {
  border: 0;
  background: none;
  color: $primary_color;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
