.large-panel {
  .ul {
    .li {
      width: 132px !important;
    }
  }

}

.multi_form {
  margin-top: 280px;

  ul {
    margin: 0px;
    width: 924px;
    margin: 0 auto;
    counter-reset: step;
    padding: 0px;

    li {
      width: 200px;
      float: left;
      color:$secondary_color;
      cursor: pointer;
      position: relative;
      font-weight: 500;
      text-align: center;
      list-style-type: none;

      &:before {
        content: "";
        width: 40px;
        height: 40px;
        border: 1px solid $secondary_color;
        background: $white;
        line-height: 40px;
        color: $secondary_color;
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        content: counter(step);
        counter-increment: step;
        z-index: 1;
      }

      &:after {
        content: "";
        width: 100%;
        left: 50%;
        height: 4px solid;
        top: -32px;
        margin-left: 18px;
        position: absolute;
        border: 1px solid $primary_color;
      }

      &:last-child:after {
        content: "";
        display: none;
      }

      @media (max-width:991px) {
        width: 25%;
      }
    }

    @media (max-width:991px) {
      width: 90%;
      margin-top: 240px;
    }

    @media (max-width:600px) {
      font-size: $font-size-10;
    }
  }

  .multi_form_complete {
    &:before {
      content: "";
      background:$secondary_color url(../images/multiform_active.svg);
      background-position: center;
      background-repeat: no-repeat;
      border: 0px;
    }

    &:after {
      content: "";
      background: $primary_color;
    }
  }

  .multi_form_progress {
    color:$secondary_color;

    &:before {
      content: "";
      background: $secondary_color;
      border: 0px;
      content: counter(step);
      counter-increment: step;
      z-index: 1;
      color: $white;
      box-shadow: 0px 0px 15px$secondary_color;
    }

    /*        &:after{
                    content: "";
                    background: $blue;
                }*/
  }

  @media (max-width:991px) {
    margin-top: 240px;
  }
}