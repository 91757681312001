

.breadcrumb{
    border-bottom: 1px solid $secondary_color;
    display: block;
    // padding-top: 6px;
    ul{
        margin: 0px;
        
        margin-bottom: 5px;
        padding: 0px;
        //  padding-left:20px;
        li{
            list-style-type: none;
            position: relative;
            margin-right: 14px;
            display: inline-block;
            a{
                text-decoration: none;
                color: $secondary_color;
                color:$secondary_color;
                font-weight: normal;
            }
            &:before{
                content: ">";
                position: absolute;
                right: -12px;
                top: 1px;
                 color: $secondary_color;
            }
            &:last-child:before{
                content: "";
                display: none;
            }
        }
    }
}