

/*////////////top_header///////////////////////*/

header{

    height: 60px;
    position: fixed;
    top: 0px;
    left: 0px;
    background-image: url(../images/header-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    z-index: 1000; 
    .logo{
        padding-left: 16px;
        float: left;
        margin-top: 1px;
        width: 170px;
        img{
          width: 100%;
        }
      }
    .user_admin{
        margin-top: 24px;
        padding-right: 16px;
        float: right;
        span{
            color: $white; 
            i{
                color: $white;   
            }
        }
        .user_pic{
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $white;
            font-weight: 500;
            height: 40px;
            border: 1px solid $gray_light;
            border-radius: 50%;
            background: $blue_blue-50;
            margin-right: 8px;
            overflow: hidden;
            img{
                width: 100%;
            }

        }
        .dropdown-menu{
            padding: 0px;
            min-width: 110px;
        }

        ul{
            padding:0px;
            li{
                color: $blue_blue-70;
                a{
                    padding: 6px 10px;
                    display: block;
                    color: $blue_blue-70;
                    text-decoration: none;
                    transition: all .05s;
                    &:hover{
                        background: $blue;
                        color: $white;
                    }
                }
                &:hover{
                    background: $blue;
                    color: $white;
                }
            }
        }
    }
}
/*////////////top_header///////////////////////*/




  
 
  

