

.custom_tab{
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
        border-bottom: 4px solid $blue;
        color: $blue;
    }
    .nav-tabs{
        border-bottom: 1px solid $blue_blue-80;
    }
    .nav-link{
        border:0px;
        padding: 0px;
        padding-right: 50px;
        padding-left: 5px;
        color: $gray;
    }
}


.profile_data_list{
    margin: 0px;
    padding: 0px;
    li{
        list-style-type: none;
        margin-bottom: 25px;
        color:$gray ;
        span{
            // float: right;
            // display: inline-table;
            // width: 65%;
            // @media (max-width:991px){
            //     width:55%;
            // }
        }
        @media (max-width:991px){
            font-size:$font-size-12;
        }
    }
}