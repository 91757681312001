/*color*/

$white :#fff;
$black :#000;
$blue :#3565b9;
$lightblue :#eff3fd;
$dark_blue :#285198;
$blue_blue-90 :#4967b1;
$blue_blue-85 :#e0e5ed;
$blue_blue-80 :#d0dbed;
$blue_blue-70 :#537cc3;
$blue_blue-60 :#6c7fa1;
$blue_blue-50 :#6db0ee;
$blue_blue-40 :#5090ff;
$gray :#808ca2;
$gray_light :#e1e1e1;
$default_color:#33425e;
$beige:#F5F5DC;

/*New Theme Color*/
$primary_color :#252525;
$secondary_color : #5dab00;
$image_gray_color :  #d7d8da;
$button_hover_color:#ffffff;
$white_color:#ffffff;
$amber:#f7b92f;

/*color*/

$font-size-10:10px;
$font-size-12:12px;
$font-size-14:14px;
$font-size-16:16px;