

.signup_wrapper{
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px){
        display: block;
    }

    .login-signup-grid_left{
        width: 600px;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 80px 40px;
        // background: $lightblue;
        @media screen and (max-width: 1199px){
            width: 50%;
        }
       
        ul{
            padding: 0px;
            margin: 0px;
            li{
                list-style-type: none;
                padding-left: 30px;
                margin-bottom: 20px;
                color: $gray;
                position: relative;
                i{
                    position: absolute;
                    left: 0px;
                }
            }
            @media screen and (max-width: 767px){
                margin-top:20px;
            }
        }
        @media screen and (max-width: 767px){
            width: 100%;
            padding:30px;
            img{
                // display: none;
            }
            h4{
                margin: 0px !important;
            }
        }
        @media screen and (max-width:1600px){
            height:auto;
        }
    }

    .signup_form{
        width: 430px;
        margin: 0 auto;
         a{

            text-decoration: underline;
        }
        @media screen and (min-width: 768px) and (max-width:1199px){
            width: 50%;
            padding:30px;
        }
        @media screen and (max-width: 767px){
            padding:30px;
            width: 100%;
            h4{
                margin-bottom: 15px !important;
            }
        }
    }
}
