


.data_table{
    width: 100%;
    th{
        background: $primary-color;
        color:$white_color;
        border: 0px;
        font-weight: 600;
        padding: 11px 10px;
        white-space: nowrap;
    }
    tr td{
        border-top:1px solid $blue_blue-85;
        color: $primary-color;
        padding: 15px 10px;
        background: #fff;
        .view_more{
            text-decoration: none;
            // float: right;
            padding-right: 15px;
            color:$blue_blue-50;
            color: $secondary-color;
            cursor: pointer;
            &:hover{
                color: $secondary-color;
            }
        }
    }
    
}

 @media (max-width:991px){
     .mt-60{
        margin-top: 60px;
     }
        }

