nav {
  height: 51px;
  position: fixed;
  z-index: 999;
  top: 76px;
  left: 0px;
  width: 100%;
  background: $blue;

  ul {
    margin: 0px;
    width: 100%;
    margin-top: 14px;
    padding: 0px;
    li {
      list-style-type: none;
      float: left;
      cursor: pointer;
      white-space: nowrap;
      color: $white;
      i {
        color: $white;
        padding: 0px 4px;
      }

      a {
        color: $white;
        border-right: 1px solid $dark_blue;
        text-decoration: none;
        position: relative;
        padding: 15px 16px;
        top: 1px;
        &:hover {
          color: $white;
        }
        &:after {
          content: "";
          position: absolute;
          right: 0px;
          top: 0px;
          width: 1px;
          height: 100%;
          background: $blue_blue-70;
        }
        @media (max-width: 991px) {
          display: block;
          border: 0px;
        }
        &.active {
          background: #4480e9;
        }
      }
      .border-none {
        border: 0px;
        &:after {
          content: "";
          display: none;
        }
      }
      .dropdown-menu {
        background: $blue;
        overflow: hidden;

        border-radius: 0px;
        margin-top: -2px !important;
        border: 0px;
        min-width: 100%;
        border-radius: 0px 0px 5px 5px;
        padding: 0px;
        li {
          padding: 0px;
          float: none;
          display: block;
          a {
            padding: 6px;
            color: $white;
            border: 0px;
            &:hover {
              background: $blue_blue-40;
              color: $white;
            }
            &:after {
              content: "";
              display: none;
            }
            @media (max-width: 991px) {
              display: block;
              padding: 6px 16px;
            }
          }
        }
      }
      @media (max-width: 991px) {
        float: none;
      }
      &:last-child {
        padding-right: 16px;
        float: right;
        @media (max-width: 991px) {
          padding-left: 16px;
          float: none;
        }
      }
    }
  }
}

.closebtn {
  display: none;
}
.menu-open-icon {
  display: none;
}

@media (max-width: 991px) {
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 90px;
    left: 0;
    background-color: $blue;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 15px;
    cursor: pointer;
    display: block;
    i {
      // color: $white;
    }
  }

  .menu-open-icon {
    position: absolute;
    left: 15px;
    top: 3px;
    cursor: pointer;
    display: block;
    i {
      color: $primary_color;
    }
  }
}
