
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50,55,60,63,65,70, 75,80,83,85,90,95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }

        .p-#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }
    }
}

@for $i from 0 through 100 {
    .opacity-#{$i} {
        opacity : round(percentage($i/100));
    }
}
.cursor-pointer{
    cursor:pointer;
}
.cursor-default{
    cursor:default !important;
}

@for $i from 1 through 100 {
    .z-index-#{$i} 
    {
        z-index : $i;
    }
}
@for $i from 1 through 100 {
    .font-size-#{$i} 
    {
        font-size:  $i + px;
    }
}